/*
Copyright (C) 2019 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

function withEndpoint(path) {
    const endpoint = process.env.VUE_APP_SERVICE_ENDPOINT_URL || '/service';
    console.log(`client.js: endpoint ${endpoint}`);
    let relativePath = path;
    if (path.startsWith('/')) {
        relativePath = path.substr(1);
    }
    const result = `${endpoint}/${relativePath}`;
    console.log(`client request to: ${result}`);
    return result;
}

async function getJson(path, query) {
    const response = await ajax.get(withEndpoint(path), {
        headers: {
            Accept: 'application/json',
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query) {
    const response = await ajax.post(withEndpoint(path), JSON.stringify(request), {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        params: query,
    });
    return response.data;
}

class Service {
    async getContext() {
        return getJson('/context');
    }

    async getVersion() {
        return getJson('/version');
    }
}

class Session {
    async get() {
        return getJson('/session');
    }

    async login(request) {
        return postJsonAcceptJson('/session/login', request);
    }

    async logout(request = {}) {
        return postJsonAcceptJson('/session/logout', request);
    }
}

class Payment {
    async getProductInfoByPriceId(priceId) {
        return getJson('/price/product', { priceId });
    }

    async createCheckoutSession({ interactionId }) {
        return postJsonAcceptJson('/checkout/session', { interactionId });
    }

    async createPaymentInteraction({ items }) {
        return postJsonAcceptJson('/interaction/create', { items });
    }

    async getPaymentInteractionById(interactionId) {
        return getJson('/interaction/retrieve', { interactionId });
    }

    async cancelPaymentInteractionById(interactionId) {
        return postJsonAcceptJson('/interaction/cancel', { interactionId });
    }
}

class Interaction {
    constructor(options, context) {
        // this.url = options.serviceEndpoint;
        this.context = context;
    }

    async create(request) {
        console.log('loginshield interaction create: request: %o', request);
        return postJsonAcceptJson('/interaction/create', request);
    }

    async get(id) {
        return getJson('/interaction', { id });
    }

    async edit(id, message) {
        return postJsonAcceptJson('/interaction/edit', message, { id });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson('/interaction/token/status', { id: tokenId });
    }
}

class Client {
    constructor() {
        this.interaction = new Interaction();
        // this.note = new Note();
        this.service = new Service();
        this.session = new Session();
        this.payment = new Payment();
    }
}

export { Client };
