import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            component: () => import(/* webpackChunkName: "front" */ './views/Front.vue'),
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: () => import(/* webpackChunkName: "checkout" */ './views/Checkout.vue'),
        },
        {
            path: '/receipt',
            name: 'receipt',
            component: () => import(/* webpackChunkName: "receipt" */ './views/Receipt.vue'),
        },
        {
            path: '/error',
            name: 'error',
            component: () => import(/* webpackChunkName: "error" */ './views/Error.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
