<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" height="56"  class="black" app>
            <v-toolbar-title>
                <a href="/" style="text-decoration: none">
                    <v-img :src="require('@/assets/logo/cryptium/logotype_reverse_black_445x125.png')" class="mr-1 float-left" max-width="160" max-height="45"/>
                </a>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="blue"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="black" right>
                        <v-tab to="/" v-show="false"></v-tab>
                        <v-tab to="/login" class="black white--text">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on">
                                <font-awesome-icon right class="white--text" icon="bars" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="black">
                            <v-tab to="/" v-show="false"></v-tab>
                            <v-tab to="/login" class="black white--text">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div>
            <div v-if="isAuthenticated">
                <v-tabs slider-color="black">
                    <!-- Show toolbar buttons on large screens -->
                    <v-tab to="/dashboard" v-show="false"></v-tab>
                    <!-- <v-tab to='/note' v-show="$vuetify.breakpoint.smAndUp">Notes</v-tab> -->
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-tab v-on="on" class="black white--text">
                                <Avatar :attr="mainProfile" :size="36"/>
                                <font-awesome-icon icon="caret-down" class="pl-2 white--text" fixed-width size="1x"/>
                            </v-tab>
                        </template>
                        <v-list class="ma-0 pa-0">
                            <div>
                                <v-tabs vertical slider-color="black" class="menutabs">
                                    <v-tab to="/dashboard" v-show="false"></v-tab>
                                    <!-- Show these in menu if screen is too small to fit buttons -->
                                    <!-- <v-tab to='/note' v-show="$vuetify.breakpoint.xsOnly">Notes</v-tab> -->
                                    <!-- Show these in menu always -->
                                    <v-tab to='/account' class="black white--text">Account</v-tab>
                                    <v-tab to='/preferences' class="black white--text">Preferences</v-tab>
                                    <v-divider></v-divider>
                                    <v-tab @click="onLogout" class="black white--text">Logout</v-tab>
                                </v-tabs>
                            </div>
                        </v-list>
                    </v-menu>
                </v-tabs>
            </div>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        Avatar,
    },
    methods: {
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push('/');
            }
        },
    },

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
